import { buttonVariants } from "@/components/Button";
import { Separator } from "@/components/Separator";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { cx } from "class-variance-authority";
import { formatISO, isToday, startOfToday } from "date-fns";
import {
    type ComponentProps,
    type ComponentPropsWithoutRef,
    type ElementRef,
    type ReactNode,
    forwardRef,
    useState,
} from "react";
import {
    DayPicker,
    type DayPickerSingleProps,
    type SelectSingleEventHandler,
} from "react-day-picker";
import { useTranslation } from "react-i18next";
import { CheveronLeftUnfilled, CheveronRightUnfilled } from "./Icons/Chevron";

type DatePickerProp = {
    children: ReactNode;
    footerText?: ReactNode;
    onSelect?: (day: Date | undefined, dayStr: string | undefined) => void;
} & Omit<DayPickerSingleProps, "mode" | "onSelect">;
export const DatePicker = ({
    children,
    footerText,
    selected,
    onSelect,
    ...props
}: DatePickerProp): JSX.Element => {
    const { t } = useTranslation("components/CalendarNew");
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const handleOnSelect: SelectSingleEventHandler = (day) => {
        const dStr = day && formatISO(day, { representation: "date" });
        onSelect?.(day, dStr);
        setIsPopperOpen(false);
    };
    const footer = (
        <div className="w-full">
            {footerText}
            <Separator
                orientation="horizontal"
                className="my-5 border-blue-grey-50"
            />
            <button
                type="button"
                disabled={selected && isToday(selected)}
                onClick={() => {
                    const today = startOfToday();
                    onSelect?.(
                        today,
                        formatISO(today, { representation: "date" }),
                    );
                    setIsPopperOpen(false);
                }}
                className="typography-overline-sm w-full cursor-pointer border-none bg-transparent text-center uppercase text-primary disabled:cursor-not-allowed disabled:opacity-50"
            >
                {t("today", "Today")}
            </button>
        </div>
    );
    return (
        <Popover open={isPopperOpen} onOpenChange={setIsPopperOpen}>
            <PopoverTrigger asChild>
                <div>{children}</div>
            </PopoverTrigger>
            <PopoverContent
                className="w-auto bg-white p-0"
                align="center"
                side="bottom"
                collisionStrategy="shift"
                collisionPadding={{ bottom: 50 }}
            >
                <Calendar
                    initialFocus
                    mode="single"
                    defaultMonth={selected}
                    selected={selected}
                    onSelect={handleOnSelect}
                    footer={footer}
                    {...props}
                />
            </PopoverContent>
        </Popover>
    );
};

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = forwardRef<
    ElementRef<typeof PopoverPrimitive.Content>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cx(
                "bg-popover z-50 w-72 rounded-md border shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                className,
            )}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export type CalendarProps = ComponentProps<typeof DayPicker>;

export const Calendar = ({
    className,
    classNames,
    showOutsideDays = true,
    ...props
}: CalendarProps): JSX.Element => (
    <DayPicker
        showOutsideDays={showOutsideDays}
        className={cx("w-[320px] py-5", className)}
        classNames={{
            months: "flex flex-col items-center",
            month: "space-y-5 divide-solid divide-blue-grey-50 divide-y divide-x-0",
            caption: "flex justify-center pt-1 relative items-center",
            caption_label:
                "text-blue-grey-900 typography-overline-lg uppercase",
            nav: "space-x-1 flex items-center",
            nav_button: cx(
                buttonVariants({ variant: "text" }),
                "size-7 bg-transparent p-0",
            ),
            nav_button_previous: "absolute left-0",
            nav_button_next: "absolute right-0",
            table: "border-collapse flex flex-col items-center",
            head_row:
                "flex text-center text-blue-grey-900 typography-sub space-y-5 items-end",
            head_cell:
                "size-10 typography-sub flex items-center justify-center",
            row: "flex w-full",
            cell: "size-10 text-center p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
            day: "typography-sub size-10 p-0 text-blue-grey-900 rounded-full border-transparent bg-transparent aria-selected:opacity-100 flex items-center justify-center disabled:hover:border-transparent disabled:cursor-default cursor-pointer hover:border-primary border border-solid",
            day_range_end: "day-range-end",
            day_selected: "aria-selected:bg-primary text-white font-bold",
            day_today: "outline-none",
            day_outside: "day-outside opacity-50 aria-selected:opacity-30",
            day_disabled: "!bg-blue-grey-20 !rounded-none !text-blue-grey-200",
            day_range_middle: "",
            day_hidden: "invisible",
            tfoot: "w-full flex [&>tr]:flex [&>tr]:flex-1 [&>tr>td]:flex-1",
            ...classNames,
        }}
        components={{
            IconLeft: () => (
                <CheveronLeftUnfilled className="size-5 text-blue-grey-900" />
            ),
            IconRight: () => (
                <CheveronRightUnfilled className="size-5 text-blue-grey-900" />
            ),
        }}
        {...props}
    />
);
Calendar.displayName = "Calendar";
